
              @import "@/assets/css/au.scss";
            

@import '@/assets/css/pages/rebateWithdraw.scss';
.form_button {
  text-align: center;
}
.my_dialog /deep/ {
  .el-dialog {
    background: #1e1e1e;
  }
  h5 {
    color: white;
  }
  .el-dialog__header {
    i {
      color: white;
    }
  }
}
