
              @import "@/assets/css/au.scss";
            

.card {
  font-size: $h6-font-size;

  .header {
    font-weight: bold;

    /deep/ em {
      font-size: $h6-font-size;
    }
  }
}
