
              @import "@/assets/css/au.scss";
            

/deep/ .el-dialog {
  .el-dialog__body {
    padding: var(--bodyPadding);
  }

  &.withdrawal-freeze-dialog {
    background: #1e1e1e;
  }
}
