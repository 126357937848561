.form_container {
  /deep/ {
    .form_list_inner {
      margin-bottom: 10px;

      .special_title {
        color: $graphite;
        font-size: $h5-font-size;
      }

      > ul {
        display: grid;
        grid-template-columns: 45% 45%;
        grid-column-gap: 10%;
      }

      .form_list_three_columns {
        display: grid;
        grid-template-columns: 32% 32% 32%;
        column-gap: 2%;
      }

      &.to > ul {
        padding: 5px;
        grid-template-columns: 100%;

        .channel {
          width: 100%;
          float: none;
          @include rtl-sass-prop(margin-right, margin-left, 0);
          margin-bottom: 0;
        }

        /* radio的样式 */
        .el-radio-group {
          width: 100%;

          li {
            @include rtl-sass-value(float, left, right);
            height: 40px;
            @include rtl-sass-prop(margin-right, margin-left, 6%);
          }
        }
      }

      .alert_info {
        font-size: 12px;
        padding-bottom: 26px;
        color: $red;
        margin-top: 10px;
      }
    }
  }

  .note {
    font-size: 12px;
    line-height: 1.667;
    color: $red;
    margin-bottom: 20px;

    /deep/ a {
      color: $white;
      text-decoration: underline;
    }
  }
}

.btn {
  .el-button {
    min-width: 15%;
  }
}

@media (max-width: 550px) {
  .form_container /deep/ .form_list_inner > ul {
    grid-template-columns: 100%;
  }
}
