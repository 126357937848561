#UploadIdOrAddress {
  /deep/ .el-dialog {
    background: $theme-hue;
  }

  /deep/ .el-dialog__body {
    padding-top: 10px;
    text-align: center;
  }

  #success /deep/ .el-dialog__body {
    word-break: normal;
    padding: 20px;
    text-align: center;

    .icon {
      font-size: 50px;
    }
  }

  .title {
    color: $white;
    font-size: 17px;
  }

  .top {
    color: $white;
    padding-top: 20px;
    margin-bottom: 30px;
    word-break: break-word;
  }

  .errMsg {
    color: $red;
    margin-bottom: 10px;
  }

  .bottom {
    width: 100%;
    max-width: 247px;
    display: inline-block;
    background-color: $white;
    border-radius: 4px;
    color: $black;
    line-height: 51px;
    cursor: pointer;
  }
}

@media (max-width: 550px) {
  .top {
    font-size: 12px;
    margin-bottom: 5px;
    padding-top: 0;
  }

  .title {
    font-size: 12px;
  }
}