#block {
  display: flex;
  flex-direction: column;
  margin: 0 30px;

  .top {
    height: 274px;
    background-color: $primary;

    .fll {
      color: $white;
      text-align: left;
      float: left;
      display: table;
      word-break: break-word;
      width: calc(100% - 201px);

      div {
        display: inline;
      }

      .span {
        display: table-cell;
        padding-top: 23px;
        padding-left: 15px;
        line-height: 1.4;
        font-size: 13px;

        .or {
          line-height: 2;
          padding-left: 5px;
        }
      }
    }

    .frr {
      position: relative;
      width: 201px;
      float: right;

      .rightImg {
        position: absolute;
        right: 0;
        height: 274px;
      }
      .proofOfAddress {
        position: absolute;
        top: 55px;
        right: 16px;
      }
    }
  }

  .middle {
    width: 100%;
    min-height: 20px;
    margin-top: 10px;
    word-break: break-word;
  }

  .bottom {
    width: 100%;
    margin-top: 20px;
    min-height: 150px;

    .el-form {
      width: 55%;
      margin: 0 auto;
      max-width: 247px;

      .el-upload {
        width: 100%;
      }
    }

    .tip {
      height: 50px;
    }
  }
}

@media (max-width: 768px) {
  #block {
    position: relative;
    margin-bottom: 10px;
  }
}

@media (max-width: 582px) {
  #block {
    width: 100%;
    max-width: 330px;
    margin: 0 auto;

    .top {
      width: 100%;

      .fll {
        z-index: 1005;
        position: absolute;
        padding-right: 5px;
        width: 100%;
      }

      .frr {
        opacity: 0.5;
        position: unset;
      }
    }

    .bottom {
      .el-form {
        width: 100%;
      }

      .tip {
        min-height: 50px;
      }
    }
  }
}
