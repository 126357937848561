
              @import "@/assets/css/au.scss";
            

.btn_color {
  color: black !important;
  & {
    color: black !important;
  }
}
