
              @import "@/assets/css/au.scss";
            

.dialog-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
  color: #ffffff;
}

.dialog-desc {
  color: #ffffff;
}

.yellow-btn {
  background: #ffcd05;
  border-color: #ffcd05;

  &:hover {
    background: #fdda4e;
    border-color: #fdda4e;
  }
}
