.label_icon {
  position: absolute;
  @include rtl-sass-value(left, 95%, 5%);
  top: -25px;
  cursor: pointer;
}

.label_icon_rtl {
  position: absolute;
  left: 0;
  top: -25px;
  cursor: pointer;
}

.disclaimer {
  margin: 20px 0;
  border-radius: 10px;

  li {
    list-style: decimal;
    margin-left: 20px;
    font-size: 16px;
    margin-top: 15px;
    font-weight: 300;
  }

  li::marker {
    color: yellow !important;
    font-weight: 600 !important;
  }
}
